<template>
  <div class="board-holder home">
    <BingoBoard theme="basic" show-logo="true" />
    <div
        id="draw-column"
        class="tile is-vertical is-parent is-9"
    >
      <div
          class="tile is-child"
      >
        <last-three-numbers :numbers="lastThree" />
        <div class="bingo-roulette">
          <p
              :class="[
              { 'reveal-number reveal-number-animation': animateTagetNum },
              'number-draw',
            ]"
          >
            {{ targetNum }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BingoBoard from '@/components/BingoBoard';
import LastThreeNumbers from '@/components/LastThreeNumbers';

export default {
  name: 'Board',
  data() {
    return {
      activeTab: 0,
      code: null,
      animateTagetNum: false,
      targetNum: 99,
      pickedNumbers: []
    }
  },
  computed: {
    lastThree: function () {
      return this.pickedNumbers.slice().reverse().slice(0, 3);
    },
  },
  methods: {
    addTicket: function(value) {
      if(this.tickets.indexOf(value) === -1) {
        this.tickets.push(value);
      }
    },
    sendLive: function(value) {
      console.log('sendLive', value)
    },
    removeTicket: function(code) {
      console.log('removeTicket', code);
      const index = this.tickets.indexOf(code);
      this.tickets.splice(index,1)
      const newActiveTab = this.tickets.length > 0 ?  this.tickets.length -1 : 0;
      console.log('newActiveTab', newActiveTab);
      this.activeTab = newActiveTab;
    },
    checkCode: function(value) {
      console.log('checkCode', value);
      this.addTicket(value);
    },
  },
  components: {
    BingoBoard,
    LastThreeNumbers
  }
}
</script>


<style>

.board-holder #numbers-column {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

#controls > div {
  display: flex;
  align-items: center;
}

.bingo-roulette {
  margin-top: 50px;
}
ul {
  list-style: none;
  padding: 0;
}



.number-draw {
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  border-radius: 100px;
  color: #fd0000;
  padding-top: 10px;
  background-color: #fcfe00;
  font-size: 100pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
